<template>
	<div>
		<!-- 头部导航 -->
		<el-affix :z-index='2001'>
		  <nav-bar></nav-bar>
		</el-affix>
		<!-- 工具栏 -->
		<tool-box></tool-box>
		<div class='order-wrap container' >
			<el-card class="box-card">
				<template #header>
					<div class="card-header">
						<div class='header-title'>
							<span>我的订单</span>
						</div>
					</div>
				</template>
				<div class="order-con">
					<ul class='order-list'>
						<li class="order-img">
							<div class='list-title'>商品信息</div>
							<div class='list-content'>
								<div class='img-content'>
									<img
									  v-if="orderData.thumb"
									  :src="orderData.thumb"
									  :title='orderData.title'
									  class="image" >
								</div>
								<div class='order-info'>
									<div class='info-title'>
										{{orderData.title}}
									</div>
<!-- 									<div class='info-period'>
										有效期：{{orderData.period1?('自开通起' + orderData.period1 + '个月'):orderData.periodValidity}}
									</div> -->
								</div>
							</div>
						</li>
						<li class='order-type'>
							<div class='list-title'>订单类型</div>
							<div class='list-content' v-if='type==1'>
								套餐
							</div>
							<div class='list-content' v-if='type==3'>
								题库
							</div>
						</li>
						<li class='order-num'>
							<div class='list-title'>数量</div>
							<div class='list-content'>1</div>
						</li>
						<li class='order-price'>
						  <div class='list-title'>金额</div>
						  <div class='list-content'>￥{{orderData.sale_price}}</div>
						</li>
					</ul>
							
					<div class='order-total'>
						<p class='price'>
						  <span>共计金额：</span>
						  <span class='money'>￥{{orderData.sale_price}}</span>
						</p>
						<p class='coupons'>
						  <span>优惠券选择：</span>
						  <el-select
						  v-model="coupons"
						  size='mini'
						  disabled
						  placeholder="请选择优惠券">
							<el-option
							  v-for="(item,index) in couponsOptions"
							  :key="index.value"
							  :label="item.label"
							  :value="item.value">
							</el-option>
						  </el-select>
						</p>
						<p class='coupons-price'>
						  <span>优惠金额：</span>
						  <span class='money'>￥{{coupons}}</span>
						</p>
					</div>
					<div class='order-btn'>
						<div class='total-price'>
						  <span>应付金额：</span>
						  <span class='money'>￥{{totalPrice}}</span>
						</div>
						<div class='order-pay'>
							<el-button
								@click='toOrderSuccess()'
								type='danger'>确认订单</el-button>
						</div>
					</div>
				</div>
			</el-card>
		</div>
		<!-- footer -->
		<web-footer></web-footer>
	</div>
</template>

<script>
	import NavBar from '@/components/page/NavBar'
	import ToolBox from '@/components/toolbox'
	import WebFooter from '@/components/page/WebFooter'
	
	import {
		getSessionStorage,
		setSessionStorage,
		setLocalStorage,
		getLocalStorage} from '@/utils/auth'
	import {mapState,mapMutations} from 'vuex';
	
	import {createOrder} from '@/api/order.js'
	export default{
		name:'createOrder',
		props:['productid','type'],
		data(){
			return{
				coupons:0,
				couponsOptions: [{
				  value: 0,
				  label: '暂无优惠券'
				},{
				  value: 100,
				  label: '100元优惠券'
				}],
				orderData:{}//订单内容
			}
		},
		components:{
			NavBar,
			ToolBox,
			WebFooter
		},
		computed:{
		  totalPrice(){
		    return this.orderData.sale_price - this.coupons;
		  }
		},
		created() {
			this.orderData = getSessionStorage('createOrderInfo');
		},
		methods:{
			toOrderSuccess(){//去付款
				if(getLocalStorage('token')){
					createOrder({
						product_id:this.orderData.id,
						title:this.orderData.title,
						type:this.type,
						order_money:this.orderData.sale_price,
						sale_money:this.totalPrice
					}).then(res=>{
						if(res.code==1){
							console.log(res.data);
							if(res.data==2){
								this.$notify.error({
								  title: '订单已生成',
								  message: '订单已生成，请在个人中心查看',
								  duration:1500,
								  showClose:false
								});
								return;
								
							}else{
								setSessionStorage('orderInfo',res.data);
								this.$router.push({
									path:'/orderpay/'+res.data.id
								});
							}
						}
					})
				}else{
					this.$notify.error({
					  title: '未登录',
					  message: '请先登录'
					});
					return;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.el-button--medium{
		padding:10px 40px;
	}
	.order-wrap{
		margin:20px auto;
		.box-card{
			padding:30px 20px;
		}
		.card-header{
		  font-size:18px;
		  display: flex;
		  flex-direction: row;
		  justify-content: space-between;
		  align-items: center;
		  margin-bottom:10px;
		  padding-left:10px;
		  border-left:3px solid $theme-color;
		  font-weight: bold;
		}
		.order-con{
		  display: flex;
		  flex-direction: column;
		  align-items: flex-end;
		}
		.order-con ul{
		  width:100%;
		  display: flex;
		  flex-direction: row;
		  padding:20px 0px;
		  li{
			display: flex;
			flex-direction: column;
			.list-title{
				height:40px;
				line-height: 40px;
				text-align:center;
				font-size:16px;
				background-color:$theme-color;
				color:#fff;
			}
			.list-content{
				height:180px;
				padding:20px 0px;
				margin:20px 0px;
				border-bottom: 1px solid $info-color;
			}
		  }
		  .order-img{
		    width:600px;
		    display: flex;
		    flex-direction:column;
			.list-content{
				display: flex;
				flex-direction: row;
				.img-content{
					width:350px;
					margin-right:10px;
					box-sizing: border-box;
					overflow: hidden;
				}
				.order-info{
					display: flex;
					flex-direction: column;
					justify-content:space-around;
					.info-title{
						font-size:16px;
					}
					.info-period{
						color:$info-color;
					}
				}
			}
		  }
		  .order-type{
		    flex:1;
			.list-content{
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				font-size:16px;
			}
		  }
		  .order-num{
		     flex:1;
			 .list-content{
			 	display: flex;
			 	flex-direction: row;
			 	align-items: center;
			 	justify-content: center;
			 	font-size:16px;
			 }
		  }
		  .order-price{
			  .list-content{
			  	display: flex;
			  	flex-direction: row;
			  	align-items: center;
			  	justify-content: center;
			  	font-size:16px;
			  }
		    flex:1;
		    .money{
		      color:$price-color;
		    }
		  }
		}
		.order-total{
		  width:300px;
		  padding:10px 0px;
		  .price{
		    margin-bottom:15px;
		    .money{
		      color:$price-color;
		      font-size: 18px;
		    }
		  }
		  .coupons{
		     margin-bottom: 15px;
		  }
		}
		.order-btn{
		  margin-top:20px;
		  display: flex;
		  flex-direction: row;
		  align-items: center;
		  justify-content:space-between;
		  width:350px;
		  .total-price{
		    color:$price-color;
		    font-size: 18px;
		  }
		}
	}
	
</style>

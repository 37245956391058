import request from '@/utils/request'
import qs from 'qs';

/**
 * 查询网站信息
 * @returns 
 */
export function createOrder(data) {//创建订单
  return request({
    url: '/addOrder',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function aliPay(data) { //支付宝
  return request({
    url: '/Unipay/pay',
    method: 'post',
    data: qs.stringify(data)
  })
}


export function aliPayCom(data) { //支付宝是否支付成功
  return request({
    url: '/Unipay/query',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function wxPay(data) { //微信
  return request({
    url: '/Unipay/pay',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function wxPayState(data) { //微信是否支付成功
  return request({
    url: '/Unipay/query',
    method: 'post',
    data: qs.stringify(data)
  })
}